<template>
    <div class="panel-list-new row review-component">
        <b-card class="col-lg-10 mx-auto">
            <div class="panel-list">
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Vendor Employee Info')">
                                <b-img class="edit-icon" :src="editIcon" /> EMPLOYEE INFO
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-3">
                            <label>Phone Number</label>
                            <p v-if="!ProdExpVendor.Phone">
                                N/A
                            </p>
                            <p>3
                                {{ ProdExpVendor.Phone }}
                            </p>
                        </b-col>
                        <b-col cols="lg-3">
                            <label>ENTITY</label>
                            <p>{{ ProdExpVendor.EntityName }}</p>
                            <p v-if="!ProdExpVendor.EntityName">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-3">
                            <label>Agency</label>
                            <p v-if="!ProdExpVendor.AgencyName">
                                N/A
                            </p>
                            <p>
                                {{ ProdExpVendor.AgencyName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-3">
                            <label>REQUEST TYPE</label>
                            <p v-if="!ProdExpVendor.RequestType">
                                N/A
                            </p>
                            <p>
                                {{ ProdExpVendor.RequestType }}
                            </p>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Vendor Attestation')">
                                <b-img class="edit-icon" :src="editIcon" /> External Vendor Form
                            </button>
                        </div>
                    </b-row>

                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>Completed Forms</label>
                            <p v-if="!file && !ProdExpVendor.CompletedForms">
                                N/A
                            </p>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in ProdExpVendor.CompletedForms" :key="file">{{ file.name ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdExpVendor.CompletedForms, ProdExpVendor.Files, 'CompletedForms')">Download</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Vendor Setup Info')">
                                <b-img class="edit-icon" :src="editIcon" /> SETUP INFO
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>BUSINESS NAME</label>
                            <p v-if="!ProdExpVendor.VendorBusinessName">
                                N/A
                            </p>
                            <p>
                                {{ ProdExpVendor.VendorBusinessName }}
                            </p>
                        </b-col>

                        <b-col cols="lg-4">
                            <label>Identification Type</label>
                            <p v-if="!ProdExpVendor.IdentificationType">
                                N/A
                            </p>
                            <p>
                                {{ formatIdentityTypeLabel(ProdExpVendor.IdentificationType) }}
                            </p>
                        </b-col>

                        <b-col cols="lg-4">
                            <label>Identification Number</label>
                            <p>
                                {{ formatIdentityType(ProdExpVendor[ProdExpVendor.IdentificationType]) }}
                            </p>
                            <p v-if="!ProdExpVendor.IdentificationType">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>{{ AttachmentLabel }}</label>
                            <p v-if="ProdExpVendor.IdentificationFiles.length <= 0">
                                N/A
                            </p>
                            <div class="d-flex align-items-start flex-column"
                                v-if="ProdExpVendor.IdentificationFiles.length > 0">
                                <a class="file-ref" v-for="file in ProdExpVendor.IdentificationFiles" :key="file">{{
                                    file.name
                                    || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdExpVendor.IdentificationFiles, ProdExpVendor.Files, 'IdentificationFiles')">Download</span>
                            </div>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Is this for former employee Cobra reimbursement?</label>
                            <p v-if="ProdExpVendor.FormerEmployeeReimbursement == 'true'">
                                Yes
                            </p>
                            <div v-if="ProdExpVendor.FormerEmployeeReimbursement == 'false'">
                                NO
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>Should this vendor receive a 1099?</label>
                            <p>
                                {{ ProdExpVendor.IsVendorReceive1099 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.IsVendorReceive1099 == 'YES'">
                            <label>Should the 1099 be issued to the same name and EIN as the vendor we are remitting payment to?</label>
                            <p>
                                {{ ProdExpVendor.IsIssuedNameEIN }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.IsIssuedNameEIN == 'NO'">
                            <label>Please confirm the name of the vendor that the 1099 should be issued to (please note that this name must appear on the first line of the vendors W-9).</label>
                            <p>
                                {{ ProdExpVendor.IssuedName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.IsIssuedNameEIN == 'NO'">
                            <label>Please confirm the TIN for this Vendor</label>
                            <p>
                                {{ ProdExpVendor.VendorTIN }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>VENDOR REMITTANCE NAME</label>
                            <p>
                                {{ ProdExpVendor.VendorRemittanceName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>COUNTRY</label>
                            <p>
                                {{ ProdExpVendor.RemittanceCountry }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>STREET ADDRESS LINE 1</label>
                            <p>
                                {{ ProdExpVendor.StreetAddressLine1 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>STREET ADDRESS LINE 2</label>
                            <p>
                                {{ ProdExpVendor.StreetAddressLine2 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.ProdExpVendor.RemittanceCountry == CountryUnitedStatesValue">
                            <label>CITY</label>
                            <p>
                                {{ ProdExpVendor.RemittanceCity }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.ProdExpVendor.RemittanceCountry == CountryUnitedStatesValue">
                            <label>STATE</label>
                            <p>
                                {{ ProdExpVendor.RemittanceState }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.ProdExpVendor.RemittanceCountry == CountryUnitedStatesValue">
                            <label>ZIP CODE</label>
                            <p>
                                {{ ProdExpVendor.RemittanceZipCode }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>VENDOR EMAIL</label>
                            <p v-if="!ProdExpVendor.RemittanceEmail">
                                N/A
                            </p>
                            <p v-if="ProdExpVendor.RemittanceEmail">
                                {{ ProdExpVendor.RemittanceEmail }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-12">
                            <label>Who at the vendor provided this information?</label>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Name</label>
                            <p>
                                {{ ProdExpVendor.InfoFromName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Title</label>
                            <p>
                                {{ ProdExpVendor.InfoFromTitle }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Telephone Number</label>
                            <p>
                                {{ ProdExpVendor.InfoFromPhone }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>How was this information obtained?</label>
                            <p v-if="!ProdExpVendor.InfoFromSourceIsVerbal && !ProdExpVendor.InfoFromSourceIsWritten">
                                N/A
                            </p>
                            <p>
                                <span v-if="ProdExpVendor.InfoFromSourceIsVerbal">Verbal</span>
                                <span v-if="ProdExpVendor.InfoFromSourceIsVerbal && ProdExpVendor.InfoFromSourceIsWritten">
                                    &
                                </span>
                                <span v-if="ProdExpVendor.InfoFromSourceIsWritten">Written</span>
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.InfoFromSourceVerbal">
                            <label>Verbal</label>
                            <p>
                                {{ ProdExpVendor.InfoFromSourceVerbal }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.InfoFromSourceWritten">
                            <label>Written</label>
                            <p>
                                {{ ProdExpVendor.InfoFromSourceWritten }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.InfoFromSourceWritten">
                            <label>Written Communication File</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in ProdExpVendor.SetupInfoSource" :key="file">{{ file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdExpVendor.SetupInfoSource, ProdExpVendor.Files, 'SetupInfoSource')">Download</span>
                            </div>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Are there invoices already obtained that require immediate payment?</label>

                            <p v-if="!ProdExpVendor.InvoicesImmediatePayment">
                                N/A
                            </p>
                            <p>
                                {{ ProdExpVendor.InvoicesImmediatePayment }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.InvoicesImmediatePayment == 'Yes'">
                            <label>Invoice Copy</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in ProdExpVendor.InvoiceCopies" :key="file">{{
                                    file.name
                                    || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdExpVendor.InvoiceCopies, ProdExpVendor.Files, 'InvoiceCopies')">Download</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Vendor Banking Info')">
                                <b-img class="edit-icon" :src="editIcon" /> BANKING INFO
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>Did the vendor indicate that they would like to be setup for ACH payments?</label>
                            <p v-if="!ProdExpVendor.ACH">
                                N/A
                            </p>
                            <p>
                                {{ ProdExpVendor.ACH }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.ACH == 'YES'">
                            <label>Did the vendor provide a bank letter, invoice or voided check confirming their account information?</label>
                            <p>
                                {{ ProdExpVendor.HasBankLetterInvoice }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.HasBankLetterInvoice == 'YES'">
                            <label>Bank Name</label>
                            <p>
                                {{ ProdExpVendor.BankName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.HasBankLetterInvoice == 'YES'">
                            <label>Bank Country</label>
                            <p>
                                {{ ProdExpVendor.BankCountry }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.HasBankLetterInvoice == 'YES'">
                            <label>BANK ADDRESS LINE 1</label>
                            <p>
                                {{ ProdExpVendor.BankAddressLine1 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.HasBankLetterInvoice == 'YES'">
                            <label>BANK ADDRESS LINE 2</label>
                            <p>
                                {{ ProdExpVendor.BankAddressLine2 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.BankCountry == CountryUnitedStatesValue">
                            <label>BANK STATE</label>
                            <p>
                                {{ ProdExpVendor.BankState }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.BankCountry == CountryUnitedStatesValue">
                            <label>BANK CITY</label>
                            <p>
                                {{ ProdExpVendor.BankCity }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.BankCountry == CountryUnitedStatesValue">
                            <label>Zip Code</label>
                            <p>
                                {{ ProdExpVendor.BankZipCode }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.HasBankLetterInvoice == 'YES'">
                            <label>Bank Account name</label>
                            <p>
                                {{ ProdExpVendor.BankAccountName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.HasBankLetterInvoice == 'YES'">
                            <label>Bank Account number</label>
                            <p>
                                {{ ProdExpVendor.BankAccountNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.HasBankLetterInvoice == 'YES'">
                            <label>Routing number</label>
                            <p>
                                {{ ProdExpVendor.BankRoutingNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.ACH == 'YES'">
                            <label>Remittance Email</label>
                            <p>
                                {{ ProdExpVendor.RemittanceAdviceEmail }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.ACH == 'YES'">
                            <label>Please attach bank letter, invoice, or voided check.</label>
                            <p v-if="ProdExpVendor.WireInfoSheets.length <= 0">
                                N/A
                            </p>
                            <div class="d-flex align-items-start flex-column"
                                v-if="ProdExpVendor.WireInfoSheets.length > 0">
                                <a class="file-ref" v-for="file in ProdExpVendor.WireInfoSheets" :key="file">{{ file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdExpVendor.WireInfoSheets, ProdExpVendor.Files, 'WireInfoSheets')">Download</span>
                            </div>

                        </b-col>
                    </b-row>
                </div>

                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Vendor Services')">
                                <b-img class="edit-icon" :src="editIcon" /> SERVICES & ANTICIPATED SPENT
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>What product or service will this vendor be providing?</label>
                            <div>
                                <p v-if="!ProdExpVendor.ProductService">
                                    N/A
                                </p>
                                <p>{{ ProdExpVendor.ProductService }}</p>
                            </div>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Anticipate level of business dollars you expect to conduct with this vendor
                                annually</label>
                            <div>
                                <p v-if="!ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor">
                                    N/A
                                </p>
                                <p> {{ ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor }} </p>
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.ServiceInfoFiles.length > 0">
                            <label>ATTACHMENT</label>
                            <p v-if="ProdExpVendor.ServiceInfoFiles.length <= 0">
                                N/A
                            </p>
                            <div class="d-flex align-items-start flex-column"
                                v-if="ProdExpVendor.ServiceInfoFiles.length > 0">
                                <a class="file-ref" v-for="file in ProdExpVendor.ServiceInfoFiles" :key="file">{{ file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdExpVendor.ServiceInfoFiles, ProdExpVendor.Files, 'ServiceInfoFiles')">Download</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <p v-if="ProdExpVendor.EmployeeVendorRelationship.length <= 0">
                        N/A
                    </p>
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Vendor Relationship')">
                                <b-img class="edit-icon" :src="editIcon" /> VENDOR/ EMPLOYEE RELATIONSHIP
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content" v-if="IsNoneSelected">
                        <template>
                            <b-col cols="lg-4">
                                <label>Employee Relationship With Vendor</label>
                                <p>None</p>
                            </b-col>
                        </template>
                    </b-row>
                    <b-row class="panel-content" v-if="ProdExpVendor.EmployeeVendorRelationship.length > 0">
                        <template v-if="!IsNoneSelected">
                            <b-col cols="lg-4" v-for="item in ProdExpVendor.EmployeeVendorRelationship" :key="item">
                                <label>{{ GetRelationshipLabel(item) }}</label>
                                <div>
                                    <p> {{ ProdExpVendor.EmployeeVendorRelationshipDetails[item] }}</p>
                                </div>
                            </b-col>
                        </template>
                    </b-row>
                    <b-row class="panel-content" >
                        <b-col cols="lg-4">
                            <label>Has any form of compensation or gifts of any type been offered to you or any person
                                related to you in exchange for placing an order with this vendor?</label>
                            <div>
                                <p v-if="!ProdExpVendor.Compensation">
                                    N/A
                                </p>
                                <p>{{ ProdExpVendor.Compensation }}</p>
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.AdditionalApproval != null">
                            <label>Additional Approval</label>
                            <div>
                                <p>{{ ProdExpVendor.AdditionalApproval }}</p>
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdExpVendor.Compensation == 'YES'">
                            <label>Compensation Dollars</label>
                            <div>
                                <p>{{ ProdExpVendor.CompensationDollers }}</p>
                            </div>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>The requestor is confirming that he/she/they has conducted all acceptable measures of due
                                diligence to ensure the propriety of the vendor and confirm.</label>
                            <div>
                                <p v-if="ProdExpVendor.Accept">YES</p>
                                <p v-if="!ProdExpVendor.Accept && ProdExpVendor.Accept != null">NO</p>
                                <p v-if="ProdExpVendor.Accept == null">N/A</p>
                            </div>
                        </b-col>
                    </b-row>
                </div>

            </div>
            <div class="d-block col-lg-10 agree-checkbox agree-checkbox-new">
                <b-form-checkbox value="true" v-model="ProdExpVendor.Attestation">By checking this
                    box, the requestor is
                    confirming that he/she/they has conducted all acceptable measures of due diligence to ensure the
                    propriety of the vendor and confirm that the information contained on the form is accuraate to the best
                    of their knowledge</b-form-checkbox>
            </div>
        </b-card>
        <b-form-group class="footer-buttons text-right">

            <button class="btn custom-button btn-link" @click="Cancel()">
                Cancel
            </button>
            <button class="btn custom-button btn-primary" v-if="CanGoToSection" @click="Previous()">
                BACK
            </button>

            <button class="btn custom-button btn-primary" :disabled="!IsFormSigned" v-if="!Edit" @click="Submit()">
                Submit
            </button>
            <button class="btn custom-button btn-primary" :disabled="!IsFormSigned" v-if="CanResubmit && !isDraft" @click="Resubmit()">
                Resubmit
            </button>
        </b-form-group>
    </div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import store from "@/store";
import downloadFile from '../../../mixins/downloadFile';
import * as status from "../../../utilities/VendorWorkflowStates";
export default {
    mixins: [downloadFile],
    data() {
        return {
            CountryUnitedStatesValue: "USA - United States",
            editIcon: require("@/assets/Icons/edit.svg"),
            RelationshipVendors: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee (name) ",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member(name)",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend (name)",
                value: "FriendName"
            },
            {
                "label": "Vendor has a relationship w/ Agency Client",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Vendor of Former employee",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Vendor was former employee of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Vendor has a relationship w/ another Agency employee (list employee name)",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ]
        };
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    computed: {

        AttachmentLabel() {
            return this.ProdExpVendor.IdentificationType == 'SocialSecurityNumber' || this.ProdExpVendor.IdentificationType == 'USTaxID' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        IsFormSigned() {
            return this.ProdExpVendor.Attestation == true || this.ProdExpVendor.Attestation == 'true';
        },
        IsNoneSelected() {
            return this.ProdExpVendor.EmployeeVendorRelationship.length == 1 && this.ProdExpVendor.EmployeeVendorRelationship.findIndex(val => val == "NONE") > -1;
        },
        ...mapGetters('prodexpvendor', {
            ProdExpVendor: 'ProdExpVendor',
        }),
        CanResubmit() {
            return (
                this.id &&
                parseInt(this.id) > 0 &&
                this.ProdExpVendor.Status == status.Canceled
            );
        },
        Edit() {
            return this.id && parseInt(this.id) > 0 && !this.isDraft;
        },
        CanGoToSection() {
            return !this.IsEdit || this.CanResubmit;
        },
        GetInfoSource() {
            let text = "";
            if (this.ProdExpVendor.infofromsourceisverbal) {
                text += "Verbal"
            }
            if (this.ProdExpVendor.infofromsourceiswritten) {
                text += text == ""
                    ? "Written"
                    : "& Written"
            }
            return text;
        },
        AcceptYesNo() {
            return this.ProdExpVendor.Accept == "true" ? "YES" : "NO";
        }
    },
    activated() {
        if (this.id) {
            // window.console.log("review step changed");
            // window.console.log(`review ${this.$route.meta.tabIndex}`);
            // this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
        }
        this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
    },
    created() {
        if (this.id) {
            // window.console.log("review step changed");
            // window.console.log(`review ${this.$route.meta.tabIndex}`);
            // this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
        }
        this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
    },
    methods: {
        formatIdentityTypeLabel(val) {
            if (val) {
                if (this.ProdExpVendor.IdentificationType == "USTaxID") {
                    return "US Tax ID";
                } else if (this.ProdExpVendor.IdentificationType == "SocialSecurityNumber") {
                    return "Social Security Number";
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        formatIdentityType(val) {
            if (val) {
                if (this.ProdExpVendor.IdentificationType == "USTaxID" && val.length > 2) {
                    if (val.indexOf("-") != 2) {
                        val = val.insert(2, '-');
                    }
                    return val;
                } else if (this.ProdExpVendor.IdentificationType == "SocialSecurityNumber") {
                    if (val.indexOf("-") != 3 && val.length > 3) {
                        val = val.insert(3, '-');

                    }
                    if (val.lastIndexOf("-") != 6 && val.length > 6) {
                        val = val.insert(6, '-');

                    }
                    const identificationType = val;
                    const lastFourDigits = identificationType.slice(-4); // Get last four digits
                    const maskedDigits = 'XXX'; // Specify the format for the first three digits
                    return maskedDigits + '-XX-' + lastFourDigits;
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Vendor Relationship",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Production Expense Vendor Relationship",
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        GetRelationshipLabel(value) {
            let relationsshipIndex = this.RelationshipVendors.findIndex(val => {
                return val.value == value;
            })
            if (relationsshipIndex > -1) {
                return this.RelationshipVendors[relationsshipIndex].label;
            } else {
                return "";
            }
        },

        GotoSection(name) {
            if (this.id) {
                this.$router.push({
                    name: name,
                    params: {
                        id: parseInt(this.id)
                    }
                });
            } else {
                this.$router.push({
                    name: name
                });
            }
            // this.$router.push(`${section}`);
        },

        PrepareEmployeeVendorRelationship(mediavendorInfo) {
            if (this.IsNoneSelected) {
                let employeeRelationship = {
                    ID: 0,
                    RelationshipName: 'NONE'
                };
                mediavendorInfo.EmployeeVendorRelationship = [];
                mediavendorInfo.EmployeeVendorRelationship.push(employeeRelationship);
            } else {
                let employeeRelationships = [];

                mediavendorInfo.EmployeeVendorRelationship.forEach(employeeRelations => {
                    employeeRelationships.push({
                        ID: 0,
                        RelationshipName: employeeRelations
                    });
                })
                mediavendorInfo.EmployeeVendorRelationship = [];
                mediavendorInfo.EmployeeVendorRelationship = employeeRelationships;
            }
        },
        PrepareData(isResubmit) {
            let mediavendorInfo = Object.assign({}, this.ProdExpVendor);
            let prodexpvendor = {};
            prodexpvendor.ID = 0;
            prodexpvendor.AnyGiftOrCompensationOfferedToYouToPlaceOrder = this.ProdExpVendor.Compensation == "YES" ? true : false;
            prodexpvendor.AdditionalApproval = this.ProdExpVendor.AdditionalApproval;
            prodexpvendor.CompensationDollers = this.ProdExpVendor.CompensationDollers;
            prodexpvendor.TypeOfProductOrServiceProvidedByVendor = this.ProdExpVendor.ProductService;
            prodexpvendor.DollarsAnticipatedToConductAnnuallyByVendor = this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor;
            prodexpvendor.VendorFormIncludeACHBankingInfo = this.ProdExpVendor.ACH == "YES" ? true : false;
            prodexpvendor.VendorBusinessName = this.ProdExpVendor.VendorBusinessName;
            prodexpvendor.IsVendorReceive1099 = this.ProdExpVendor.IsVendorReceive1099 == "YES" ? true : false;
            prodexpvendor.FormerEmployeeReimbursement = this.ProdExpVendor.FormerEmployeeReimbursement == "true" ? true : false;
            prodexpvendor.IsIssuedNameEIN = this.ProdExpVendor.IsIssuedNameEIN == "YES" ? true : false;
            prodexpvendor.IssuedName = this.ProdExpVendor.IssuedName;
            prodexpvendor.VendorTIN = this.ProdExpVendor.VendorTIN;
            prodexpvendor.HasBankLetterInvoice = this.ProdExpVendor.HasBankLetterInvoice == "YES" ? true : false;
            prodexpvendor.BankName = this.ProdExpVendor.BankName;
            prodexpvendor.BankCountry = this.ProdExpVendor.BankCountry;
            prodexpvendor.BankAddressLine1 = this.ProdExpVendor.BankAddressLine1;
            prodexpvendor.BankAddressLine2 = this.ProdExpVendor.BankAddressLine2;
            prodexpvendor.BankCity = this.ProdExpVendor.BankCity;
            prodexpvendor.BankState = this.ProdExpVendor.BankState;
            prodexpvendor.BankZipCode = this.ProdExpVendor.BankZipCode;
            prodexpvendor.BankAccountName = this.ProdExpVendor.BankAccountName;
            prodexpvendor.BankAccountNumber = this.ProdExpVendor.BankAccountNumber;
            prodexpvendor.BankRoutingNumber = this.ProdExpVendor.BankRoutingNumber;
            prodexpvendor.InvoiceCopies = this.ProdExpVendor.InvoiceCopies;
            prodexpvendor.IdentificationType = this.ProdExpVendor.IdentificationType;
            prodexpvendor.IdentificationFiles = this.ProdExpVendor.IdentificationFiles;
            prodexpvendor.RequestType = this.ProdExpVendor.RequestType;
            prodexpvendor.USTaxID = this.ProdExpVendor.USTaxID;
            prodexpvendor.Foreign = this.ProdExpVendor.Foreign;
            prodexpvendor.SocialSecurityNumber = this.ProdExpVendor.SocialSecurityNumber;
            prodexpvendor.RemittanceAdviceEmail = this.ProdExpVendor.RemittanceAdviceEmail;
            prodexpvendor.EmployeeTitle = this.ProdExpVendor.Title;
            prodexpvendor.EmployeePhoneNumber = this.ProdExpVendor.Phone;
            prodexpvendor.Entity = this.ProdExpVendor.Entity;
            prodexpvendor.EmployeeAgency = this.ProdExpVendor.EmployeeAgency;
            prodexpvendor.AgencyName = this.ProdExpVendor.AgencyName;
            prodexpvendor.WireInfoSheets = this.ProdExpVendor.WireInfoSheets;
            this.PrepareEmployeeVendorRelationship(mediavendorInfo);
            prodexpvendor.EmployeeVendorRelationship = mediavendorInfo.EmployeeVendorRelationship;
            prodexpvendor.OmniflowProjectID = 0;
            prodexpvendor.OmniflowProcessID = 0;
            prodexpvendor.Status = new Date();
            prodexpvendor.CompletionDate = new Date();
            prodexpvendor.InActive = false;
            prodexpvendor.EmployeeVendorRelationshipDetails = this.ProdExpVendor.EmployeeVendorRelationshipDetails;
            prodexpvendor.infofromname = this.ProdExpVendor.InfoFromName;
            prodexpvendor.infofromtitle = this.ProdExpVendor.InfoFromTitle;
            prodexpvendor.infofromphone = this.ProdExpVendor.InfoFromPhone;
            prodexpvendor.infofromsourceisverbal = this.ProdExpVendor.InfoFromSourceIsVerbal;
            prodexpvendor.infofromsourceiswritten = this.ProdExpVendor.InfoFromSourceIsWritten;
            prodexpvendor.infofromsourceverbal = this.ProdExpVendor.InfoFromSourceVerbal;
            prodexpvendor.infofromsourcewritten = this.ProdExpVendor.InfoFromSourceWritten;
            prodexpvendor.infofromwrittenattachment = this.ProdExpVendor.InfoFromWrittenAttachment;
            prodexpvendor.invoicesimmediatepayment = this.ProdExpVendor.InvoicesImmediatePayment;
            prodexpvendor.DollarsAnticipatedToConductAnnuallyByVendor = this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor;
            prodexpvendor.VendorRemittanceName = this.ProdExpVendor.VendorRemittanceName;
            prodexpvendor.RemittanceCountry = this.ProdExpVendor.RemittanceCountry;
            prodexpvendor.StreetAddressLine1 = this.ProdExpVendor.StreetAddressLine1;
            prodexpvendor.StreetAddressLine2 = this.ProdExpVendor.StreetAddressLine2;
            prodexpvendor.RemittanceCity = this.ProdExpVendor.RemittanceCity;
            prodexpvendor.RemittanceState = this.ProdExpVendor.RemittanceState;
            prodexpvendor.RemittanceZipCode = this.ProdExpVendor.RemittanceZipCode;
            prodexpvendor.RemittanceEmail = this.ProdExpVendor.RemittanceEmail;
            if (isResubmit == true) {
                prodexpvendor.Files = [];
                this.GetFilesCategory().forEach(name => {
                    this.AttachUpdatedFiles(prodexpvendor.Files, name);
                });
            }
            return prodexpvendor;
        },
        AttachUpdatedFiles(filesArray, fileCategory) {
            let files = this.ProdExpVendor[fileCategory];
            if (typeof files == "undefined" || files == null) {
                return;
            }
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                filesArray.push({ Category: fileCategory, Name: file.Name || file.name })
            }
        },
        AttachFiles(formData, fileCategory) {
            let files = this.ProdExpVendor[fileCategory];

            if (typeof files == "undefined") {
                return;
            }

            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append(`${fileCategory}[${i}]`, file.file);
            }
        },
        GetFilesCategory() {
            return ["IdentificationFiles", "SetupInfoSource", "ServiceInfoFiles", "CompletedForms", "WireInfoSheets", "InvoiceCopies"];
        },

        Submit() {
            let formData = new FormData();
            let data = this.PrepareData();
            if (this.IsEdit == true && this.isDraft == true) {
                data.DraftRequestID = parseInt(this.id);
            }
            this.GetFilesCategory().forEach(name => {
                this.AttachFiles(formData, name);
            });

            formData.append('request', JSON.stringify(data));
            this.$store
                .dispatch("prodexpvendor/AddRequestWithFiles", formData)
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your Production Expense Vendor request has been submitted!"
                    );
                })
                .catch((ex) => {
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            // for (var i = 0; i < ex.response.data.length; i++) {
                            //     if (ex.response.data[i].ErrorMessage != "undefined") {
                            //         commenmsg += ex.response.data[i].ErrorMessage;
                            //     }
                            // }
                            store.dispatch("progress/setErrorMessage", `Please check your inputs or try again later`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `Please check your inputs or try again later`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while creating Production Expense Vendor request. ${ex}`
                        );
                    }
                });
        },
        Resubmit() {
            let data = this.PrepareData(true);
            if (this.IsEdit == true && this.isDraft == true) {
                data.DraftRequestID = parseInt(this.id);
            }
            let formData = new FormData();
            this.GetFilesCategory().forEach(name => {
                this.AttachFiles(formData, name);
            });
            formData.append('request', JSON.stringify(data));
            console.log(formData);
            this.$store
                .dispatch("prodexpvendor/UpdateRequestWithFiles", {
                    id: parseInt(this.id),
                    data: formData
                })
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your Production Expense Vendor has been updated!"
                    );
                })
                .catch((ex) => {
                    window.console.error(JSON.stringify(ex));
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while updating Production Expense Vendor request. ${ex}`
                        );
                    }
                });
        },
    },
};
</script>

<style scoped>
span.btn.btn-primary.btn-view {
    height: 0;
    width: 94px;
    min-width: 94px;
    padding: 0;
    margin: 0;
    min-height: 22px;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.modal-body p {
    margin: 14px 40px 14px 55px;
    font-family: 'Open Sans' !important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: #4d5161;
}

.add-product-row {
    font-weight: bold;
    padding-left: 0px;
    height: 9px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list:not(.media-dropdowns) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
    font-weight: 600;
}

.panel-content.row>div>label:first-child {
    color: #A2A9AD !important;
    font-family: "Open Sans" !important;
    font-size: 10px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div> :not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}
</style>
